<template>
  <div>
    <div class="zt-block block-1">
      <div class="info">
        <div class="info-top">
          <div class="logo">
            <img v-if="compInfo" :src="imgUrl + compInfo.logo+'!width_250px'" />
            <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
          </div>
          <div class="logo-right">
            <div v-if="compInfo" class="name">
              {{compInfo.name}}
              <div v-if="data.aptitude && data.aptitude.real_check && data.aptitude.real_check.expired_at > 0" class="auth">实地认证</div>
            </div>
            <div>
              <a @click="get_phone()">
                <i class="iconfont icon-dianhuabeifen"></i> 获取联系方式（{{phoneNum && phoneNum.total}}）</a>
            </div>
            <div>
              <span>公司规模：</span>
              {{data.aptitude&&data.aptitude.stat&&data.aptitude.stat.n_employee}}
            </div>
            <div>
              <span>注册时间：</span>
              {{data.aptitude && data.aptitude.stat && data.aptitude.stat.reg_date | moment('YYYY年MM月')}}
            </div>
          </div>
        </div>
        <div class="info-bottom">
          <label v-for="tag in tags" :key="tag.k" :class="'color-'+randomInt(4)">
            <span>{{tag.v}}</span>
          </label>
        </div>
      </div>
      <div class="data">
        <div class="data-item">
          交货准时
          <span>93%</span>
        </div>
        <div class="data-item">
          上新率
          <span>93%</span>
        </div>
        <div class="data-item">
          累计销售
          <span>1231</span>
        </div>
      </div>
    </div>
    <div class="zt-block block-2">
      <div>
        <div v-if="data.aptitude&&data.aptitude.ability_service&&data.aptitude.ability_service.jgsx&&data.aptitude.ability_service.jgsx.length>0">
          <span>生产属性</span>
          <div>
            <label
              v-for="item in data.aptitude.ability_service.jgsx"
              :key="item.k"
              :class="'color-' + randomInt(4)"
            >{{item.v}}</label>
          </div>
        </div>
        <div v-if="data.aptitude&&data.aptitude.ability_service&&data.aptitude.ability_service.zdcp.length > 0">
          <span>主打产品</span>
          <div>
            <label
              v-for="item in data.aptitude.ability_service.zdcp"
              :key="item.k"
              :class="'color-' + randomInt(4)"
            >{{item.v}}</label>
          </div>
        </div>
      </div>
      <div>
        <div v-if="data.aptitude&&data.aptitude.ability_service&&data.aptitude.ability_service.mczl.length > 0">
          <span>主加工木材</span>
          <div>
            <label
              v-for="item in data.aptitude.ability_service.mczl"
              :key="item.k"
              :class="'color-' + randomInt(4)"
            >{{item.v}}</label>
          </div>
        </div>
        <div v-if="data.aptitude&&data.aptitude.ability_service&&data.aptitude.ability_service.wx.length > 0">
          <span>外协加工</span>
          <div>
            <label
              v-for="item in data.aptitude.ability_service.wx"
              :key="item.k"
              :class="'color-' + randomInt(4)"
            >{{item.v}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="zt-block block-3">
      <div class="tit">
        <span>工艺能力</span>
      </div>
      <div class="content">
        <div class="tabs" v-if="data.aptitude && data.aptitude.ability_make.length>0">
          <div
            v-for="(item, i) in data.aptitude.ability_make"
            :key="i"
            class="tab-item"
            :ref="'el' + i"
            :class="{active: tabIndex === i,'is-main': item.primary === 1}"
            @click="changeTab(i)"
          >
            {{item.name}}
            <span v-if="item.primary">主</span>
            <i class="el-icon-d-arrow-right"></i>
          </div>
          <div class="prev" @click="prev()"></div>
          <div class="next" @click="next()"></div>
        </div>
        <div class="tab-body" v-if="data.aptitude && data.aptitude.ability_make.length>0">
          <div
            v-for="(item, i) in data.aptitude.ability_make"
            :key="i"
            v-show="tabIndex === i"
            class="tab-wrap"
          >
            <div>指标与评价</div>
            <div
              v-for="n in item.quota"
              :key="n.id"
            >{{n.name}}{{n.value && ('：' + (typeof n.value == 'string'?n.value : n.value.join(';')))}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="zt-block block-4">
      <div class="tit">
        <span>相关介绍</span>
      </div>
      <div class="content">
        <div>
          <label>工厂面积</label>
          <div v-if="data.aptitude && data.aptitude.stat">{{data.aptitude.stat.area}} ㎡</div>
        </div>
        <div>
          <label>总产值</label>
          <div v-if="data.aptitude && data.aptitude.stat">{{data.aptitude.stat.amount}} 万</div>
        </div>
        <div>
          <label>生产设备</label>
          <div v-if="data.aptitude && data.aptitude.stat">{{data.aptitude.stat.n_equipment}} 台</div>
        </div>
        <div>
          <label>所在区域</label>
          <div v-if="data.aptitude && data.aptitude.stat">{{data.aptitude.stat.zone}}</div>
        </div>
        <div>
          <label>工厂简介</label>
          <div v-if="data.aptitude && data.aptitude.stat">{{data.aptitude.stat.intro}}</div>
        </div>
        <div class="project-pic">
          <label>项目图片</label>
          <div v-if="data.aptitude && data.aptitude.stat && data.aptitude.stat.photo.length>0">
            <img v-for="(item, i) in data.aptitude.stat.photo" :key="i" :src="imgUrl+item+'!width_250px'" />
          </div>
        </div>
        <!-- <div class="factory-video">
          <label>工厂视频</label>
          <div>
            <img v-for="(item, i) in data.aptitude.stat.video" :key="i" :src="item" />
          </div>
        </div>-->
        <div>
          <label>工厂官网</label>
          <div>
            <a>{{data.aptitude&&data.aptitude.relation&&data.aptitude.relation.site}}</a>
          </div>
        </div>
        <div class="honor-pic">
          <label>获奖荣誉</label>
          <div>
            <div v-if="data.aptitude && data.aptitude.relation && data.aptitude.relation.honor.length>0">
              <img v-for="(item, i) in data.aptitude.relation.honor" :key="i" :src="imgUrl+item+'!width_250px'" />
            </div>
          </div>
        </div>
        <div class="auth-pic">
          <label>企业认证</label>
          <div>
            <div v-if="data.aptitude && data.aptitude.relation && data.aptitude.relation.iso.length>0">
              <img v-for="(item, i) in data.aptitude.relation.iso" :key="i" :src="imgUrl+item+'!width_250px'" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="popVisible" 
      width="500px" :destroy-on-close="true">
      <ItemPhone :type="5" :compId="comp.id" @hide="popVisible=false"></ItemPhone>
    </el-dialog>

  </div>
</template>

<script>
import { getCompanyInfo } from "@/service/sale";
import { IMG_URL_PRE,STATIC_URL_PRE } from "@/config";
import { getFactoryZones, getPhoneNum } from "@/service/company";
import ItemPhone from '@/components/comp/ItemPhone.vue';
export default {
  components: { ItemPhone },
  props:['comp'],
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl:STATIC_URL_PRE,
      popTitle: null,
      popVisible: false,
      tabIndex: 0,
      compInfo: {},
      data: {},
      tags: [],
      empOptions: [
        { value: 1, label: '1-20人' },
        { value: 2, label: '21-50人' },
        { value: 3, label: '51-100人' },
        { value: 4, label: '101-200人' },
        { value: 5, label: '201-500人' },
        { value: 6, label: '501-1000人' },
        { value: 7, label: '1001人以上' }
      ],
      zoneOptions: [],
      phoneNum: '', // 查看电话号码的数量
    };
  },
  computed: {},
  async created() {
      this.compInfo = this.comp;
      this.data.aptitude = this.compInfo.aptitude;
      if (this.data.aptitude&&this.data.aptitude.stat&&this.data.aptitude.stat.n_employee) {
        let obj=this.empOptions.find((e)=> {
            return e.value == this.data.aptitude.stat.n_employee;
        })
        this.data.aptitude.stat.n_employee = obj.label;
      }
      if (this.data.aptitude && this.data.aptitude.stat && this.data.aptitude.stat.zone) {
        this.zoneOptions = await getFactoryZones();
        if (this.zoneOptions && this.zoneOptions.length>0) {
          let obj1=this.zoneOptions.find((e)=> {
              return e.id == this.data.aptitude.stat.zone;
          })
          this.data.aptitude.stat.zone = obj1.name;
        }
      }
      this.phoneNum = await getPhoneNum({comp_id:this.compInfo.id,channel:5});
      // if (!this.data.aptitude || this.data.aptitude.ability_service.jgsx || this.data.aptitude.ability_service.mczl || this.data.aptitude.ability_service.xsbq || this.data.aptitude.ability_service.fwbq) {
      //   return
      // }
      // const {
      //   aptitude: {
      //     ability_service: { tags }
      //   }
      // } = this.data;
      // this.tags = [...tags.jgsx, ...tags.mczl, ...tags.xsbq, ...tags.fwbq];
      
      // const id = this.$route.params.id || 3;
      // getCompanyInfo(id).then(res => {
      //   this.compInfo = res;
      //   this.data.aptitude = res.aptitude;
      //   if (!this.data.aptitude || this.data.aptitude.ability_service.jgsx || this.data.aptitude.ability_service.mczl || this.data.aptitude.ability_service.xsbq || this.data.aptitude.ability_service.fwbq) {
      //     return
      //   }
      //   const {
      //     aptitude: {
      //       ability_service: { tags }
      //     }
      //   } = this.data;
      //   this.tags = [...tags.jgsx, ...tags.mczl, ...tags.xsbq, ...tags.fwbq];
      // });
  },
  methods: {
    get_phone() {
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    },
    prev() {
      const el = this.$refs.el0[0];
      if (parseInt(el.style.marginLeft) === 0) {
        return;
      }
      const W = el.offsetWidth * 4;
      el.style.marginLeft = parseInt(el.style.marginLeft) + W + "px";
    },
    next() {
      const el = this.$refs.el0[0];
      const W = el.offsetWidth * 4;
      if (
        parseInt(el.style.marginLeft) - W + this.gy.length * el.offsetWidth <
        0
      ) {
        return;
      }
      el.style.marginLeft = parseInt(el.style.marginLeft) - W + "px";
    },
    changeTab(i) {
      this.tabIndex = i;
    }
  }
};
</script>

<style scoped lang="less">
.zt-block {
  padding: 32px 9px;
  .tit {
    font-size: 18px;
    color: #4d4d4d;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
}
.block-1 {
  padding: 32px 80px;
  background: linear-gradient(
    180deg,
    rgba(70, 184, 165, 1) 0%,
    rgba(154, 235, 193, 1) 100%
  );
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  .info {
    display: flex;
    flex-direction: column;
    .info-top {
      display: flex;
      .logo {
        width: 100px;
        height: 100px;
        margin-right: 40px;
        flex-shrink: 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .logo-right {
        color: #4d4d4d;
        flex-shrink: 0;
        flex-grow: 1;
        position: relative;
        .name {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          position: relative;
          display: flex;
          align-items: center;
          .auth {
            width: 72px;
            height: 24px;
            background: rgba(25, 216, 25, 1);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-left: 24px;
            flex-shrink: 0;
          }
        }
        > div {
          margin-top: 5px;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .info-bottom {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 500px;
      flex-shrink: 0;
      label {
        margin-bottom: 10px;
        margin-right: 14px;
        padding: 5px;
        border-radius: 17px;
        background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
        &.color-1 {
          background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
        }
        &.color-2 {
          background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
        }
        &.color-4 {
          background: linear-gradient(180deg, #8262e4 0%, #8262e4 100%);
        }
        &.color-3 {
          background: linear-gradient(
            360deg,
            rgba(70, 184, 165, 1) 0%,
            rgba(154, 235, 193, 1) 100%
          );
        }
        span {
          color: #fff;
          border: 1px solid #fff;
          height: 24px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 15px;
        }
      }
    }
  }
  .data {
    width: 284px;
    padding: 13px 22px;
    height: 78px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    display: flex;
    .data-item {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      color: #292929;
      border-right: 1px solid #fff;
      &:last-child {
        border-right: 0;
      }
      span {
        font-size: 18px;
        color: #f66f6a;
      }
    }
  }
}
.block-2 {
  margin-top: 0;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 30px 100px;
  > div {
    display: flex;
    display: table-column;
    width: 500px;
    flex-shrink: 0;
    > div {
      display: flex;
      &:first-child {
        margin-top: 0;
      }
      span {
        color: #808080;
        font-size: 16px;
        font-weight: bold;
        margin-right: 12px;
      }
      > div {
        display: flex;
        width: 300px;
        flex-shrink: 0;
        flex-wrap: wrap;
        label {
          color: #fd9c95;
          border: 1px solid #fd9c95;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          flex-wrap: wrap;
          border-radius: 13px;
          height: 26px;
          padding: 0 10px;
          margin-right: 15px;
          margin-bottom: 15px;
          &.color-1 {
            color: #fd9c95;
            border: 1px solid #fd9c95;
          }
          &.color-2 {
            color: #448ff1;
            border: 1px solid #448ff1;
          }
          &.color-3 {
            color: #7b7af3;
            border: 1px solid #7b7af3;
          }
          &.color-4 {
            color: #72d3b3;
            border: 1px solid #72d3b3;
          }
          &.color-5 {
            color: #efb759;
            border: 1px solid #efb759;
          }
        }
      }
    }
  }
}
.block-3 {
  .content {
    display: flex;
    color: #808080;
    margin-top: 16px;
    .tabs {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin: 0 13px 0 85px;
      .prev,
      .next {
        display: none;
      }
      .tab-item {
        width: 112px;
        height: 32px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        transition: margin-left 0.3s;
        &:first-child {
          margin-top: 0;
        }
        &.active {
          color: #016fff;
          border: 1px solid #016fff;
          i {
            display: flex;
          }
        }
        &.is-main {
          span {
            display: flex;
          }
        }
        span {
          display: none;
          color: #fff;
          width: 16px;
          height: 16px;
          align-items: center;
          justify-content: center;
          background: #f66f6a;
          border-top-left-radius: 6px;
          border-bottom-right-radius: 6px;
          font-size: 12px;
          position: relative;
          top: -6px;
        }
        i {
          display: none;
          position: absolute;
          right: 9px;
          font-size: 16px;
        }
      }
    }
    .tab-body {
      .tab-wrap {
        width: 647px;
        height: 100%;
        background: rgba(245, 245, 245, 1);
        border: 1px solid rgba(204, 204, 204, 0.5);
        border-radius: 4px;
        div {
          margin-left: 72px;
          margin-top: 9px;
          &:first-child {
            margin-left: 32px;
            color: #4d4d4d;
            font-weight: bold;
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
.block-4 {
  .content {
    color: #808080;
    padding: 32px 0;
    > div {
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      label {
        font-size: 16px;
        flex-shrink: 0;
        margin-right: 16px;
        margin-left: 112px;
      }
    }
    .project-pic,
    .factory-video {
      img {
        width: 140px;
        height: 140px;
        margin-right: 65px;
      }
    }
    .honor-pic {
      img {
        height: 94px;
        margin-right: 65px;
      }
    }
    .auth-pic {
      img {
        height: 140px;
        margin-right: 100px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .zt-block {
    padding: 8px;
    margin: 10px;
    border-radius: 4px;
    .tit {
      font-size: 16px;
      color: #808080;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 4px;
      span {
        border-left: 6px solid #2bbaae;
        padding-left: 8px;
      }
    }
  }
  .block-1 {
    padding: 24px 8px 8px 8px;
    flex-direction: column;
    .info {
      display: flex;
      flex-direction: column;
      margin-bottom: 13px;
      .info-top {
        display: flex;
        .logo {
          margin-right: 6px;
        }
        .logo-right {
          width: 180px;
          .name {
            font-size: 18px;
            .auth {
              position: absolute;
              right: -34px;
              bottom: -26px;
            }
          }
        }
      }
      .info-bottom {
        display: none;
      }
    }
    .data {
      padding: 6px 0;
      height: 52px;
    }
  }
  .block-2 {
    padding: 8px;
    > div {
      width: auto;
      > div {
        > div {
          width: 250px;

          label {
            margin-right: 10px;
            margin-bottom: 10px;
            &.color-1 {
              color: #fd9c95;
              border: 1px solid #fd9c95;
            }
            &.color-2 {
              color: #448ff1;
              border: 1px solid #448ff1;
            }
            &.color-3 {
              color: #7b7af3;
              border: 1px solid #7b7af3;
            }
            &.color-4 {
              color: #72d3b3;
              border: 1px solid #72d3b3;
            }
            &.color-5 {
              color: #efb759;
              border: 1px solid #efb759;
            }
          }
        }
      }
    }
  }
  .block-3 {
    .content {
      flex-direction: column;
      margin-top: 8px;
      .tabs {
        flex-direction: row;
        margin: 0;
        overflow: hidden;
        position: relative;
        .prev,
        .next {
          display: block;
          position: absolute;
        }
        .prev {
          width: 30px;
          height: 100%;
          position: absolute;
          left: -5px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 4px solid transparent;
            border-right: 4px solid #808080;
          }
        }
        .next {
          width: 30px;
          height: 100%;
          position: absolute;
          right: -5px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid transparent;
            border-left: 4px solid #808080;
          }
        }
        .tab-item {
          width: 25%;
          height: 32px;
          flex-shrink: 0;
          border-radius: 0;
          justify-content: center;
          margin-top: 0;
          position: relative;
          padding-left: 0;
          &.active {
            color: #016fff;
            border: 0;
            i {
              display: none;
            }
          }
          &.is-main {
            span {
              display: none;
            }
          }
          i {
            display: none;
            position: absolute;
            right: 9px;
            font-size: 16px;
          }
        }
      }
      .tab-body {
        .tab-wrap {
          width: auto;
          height: 156px;
          background: rgba(245, 245, 245, 1);
          border: 1px solid rgba(204, 204, 204, 0.5);
          border-radius: 4px;
          div {
            margin-left: 72px;
            margin-top: 9px;
            &:first-child {
              margin-left: 32px;
              color: #4d4d4d;
              font-weight: bold;
              font-size: 16px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  .block-4 {
    .content {
      padding: 8px 0;
      > div {
        flex-direction: column;
        label {
          font-size: 14px;
          margin-left: 12px;
        }
        a {
          margin-left: 12px;
        }
        > div {
          display: flex;
          flex-wrap: wrap;
        }
        &:first-child {
          > div {
            padding-left: 12px;
          }
        }
      }
      .project-pic,
      .factory-video {
        img {
          width: 100px;
          height: 100px;
          margin-right: 13px;
          margin-top: 13px;
        }
      }
      .honor-pic {
        img {
          height: 94px;
          margin-right: 13px;
          margin-top: 13px;
        }
      }
      .auth-pic {
        img {
          margin-right: 13px;
          margin-top: 13px;
        }
      }
    }
  }
}
</style>