<template>
  <div :class="'color-' + randomInt(4)">
    <span>{{name}}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: String
  },
  methods: {
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    }
  }
};
</script>

<style scoped lang="less">
div {
  margin-bottom: 10px;
  margin-right: 14px;
  padding: 4px 8px;
  border-radius: 17px;
  background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
  display: inline-block;
  &.color-1 {
    background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
  }
  &.color-2 {
    background: linear-gradient(180deg, #feb095 0%, #fd8995 100%);
  }
  &.color-4 {
    background: linear-gradient(180deg, #8262e4 0%, #8262e4 100%);
  }
  &.color-3 {
    background: linear-gradient(
      360deg,
      rgba(70, 184, 165, 1) 0%,
      rgba(154, 235, 193, 1) 100%
    );
  }
  span {
    color: #fff;
    border: 1px solid #fff;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }
}
</style>