<template>
  <div v-if="compInfo">
    <div class="block block-1">
      <div class="block-1-top">
        <div class="container">
          <div class="comp-info">
            <div class="info-top">
              <img v-if="compInfo && compInfo.logo" :src="imgUrl + compInfo.logo" />
              <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
              <div class="img-right">
                <div v-if="compInfo" class="name">{{compInfo.name}}</div>
                <div>
                  <a @click="get_phone()">
                    <i class="iconfont icon-dianhuabeifen"></i> 获取联系方式（{{phoneNum && phoneNum.total}}）</a>
                </div>
                <div class="flex">
                  <div v-if="compInfo && compInfo.aptitude" style="margin-right:32px">
                    <span>公司规模：</span>
                    {{compInfo.aptitude.stat.n_employee}}
                  </div>
                  <div v-if="compInfo && compInfo.aptitude && compInfo.aptitude.stat">
                    <span>注册时间：</span>
                    {{compInfo.aptitude.stat.reg_date | moment('YYYY年MM月')}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="compInfo && compInfo.aptitude && compInfo.aptitude.ability_service" class="label-wrap">
              <Label
                v-for="(item, i) in compInfo.aptitude.ability_service.range"
                :key="i"
                :name="item.v"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="compInfo.aptitude && compInfo.aptitude.stat" class="block-1-bottom container">
        <div style="padding:0 100px;" class="flex">
          <div class="b1b-title">公司简介</div>
          <div class="flex-1">{{compInfo.aptitude.stat.intro}}</div>
        </div>
      </div>
    </div>
    <div class="block block-2">
      <div class="container">
        <h3>历史案例</h3>
        <div
          v-if="compInfo.aptitude && compInfo.aptitude.case && compInfo.aptitude.case.length>0"
          class="block-2-list"
        >
          <div v-for="(item,idx) in compInfo.aptitude.case" :key="idx" class="block-2-item">
            <ImgView :list="compInfo.aptitude.case" :idx="idx" width="282" height="282"></ImgView>
            <div class="b2i-title">{{item.name}}</div>
            <div class="b2i-desc">{{item.intro}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-3">
      <div class="container">
        <h3>品牌历程</h3>
        <div
          v-if="compInfo.aptitude && compInfo.aptitude.brand && compInfo.aptitude.brand.length>0"
          class="block-3-list"
        >
          <div class="b3i-line"></div>
          <div v-for="(brand,idx) in compInfo.aptitude.brand" :key="idx" class="block-3-item flex">
            <div class="b3i-square"></div>
            <div class="b3i-con flex-1">
                <div v-if="brand.date" class="b3i-time">{{parseInt(brand.date) | moment("YYYY/MM/DD")}}</div>
                <div class="b3i-text">{{brand.intro}}</div>
            </div>
            <ImgView :list="compInfo.aptitude.brand" :idx="idx" width="140" height="105"></ImgView>
          </div>
          <div v-if="compInfo.aptitude.brand.length<2" class="block-3-item flex">
            <div class="b3i-square"></div>
            <div class="b3i-con flex-1">
                <div class="b3i-time">发展中</div>
                <div style="height:40px" class="b3i-text"></div>
            </div>
          </div>
        </div>
        <div v-if="compInfo.aptitude && compInfo.aptitude.brand && compInfo.aptitude.brand.length==0"
            style="padding:30px 0;text-align:center;">
            暂无品牌历程
        </div>
      </div>
    </div>
    <div class="block block-4">
      <div class="container">
        <h3>团队展示</h3>
        <div
          v-if="compInfo.aptitude && compInfo.aptitude.team && compInfo.aptitude.team.length>0"
          class="block-4-list"
        >
          <div v-for="(item,idx) in compInfo.aptitude.team" :key="idx" class="block-4-item flex">
            <ImgView :list="compInfo.aptitude.team" :idx="idx" width="300" height="225" style="margin-right:25px"></ImgView>
            <!-- <div style="width:300px;height:225px;margin-right:98px;">
              <div
                v-if="item.photo && item.photo.length>0"
                class="bg_img"
                :style="{backgroundImage: 'url(' + imgUrl+item.photo[0]+'!width_250px' + ')'}"
              ></div>
            </div> -->
            <div class="flex-1">
                <div class="b4i-title">{{item.name}}</div>
                <div class="b4i-desc">{{item.intro}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-5">
      <div class="container">
        <h3>奖项证书：</h3>
        <div class="partner flex" v-if="compInfo && compInfo.aptitude">
          <img
            v-for="(item, i) in compInfo.aptitude.prize"
            :key="i"
            :src="imgUrl + item"
          />
        </div>
      </div>
    </div>
    <div class="block block-5">
      <div class="container">
        <h3>合作伙伴：</h3>
        <div class="partner flex" v-if="compInfo && compInfo.aptitude">
          <img
            v-for="(item, i) in compInfo.aptitude.relationship"
            :key="i"
            :src="imgUrl + item"
          />
        </div>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="popVisible" 
      width="500px" :destroy-on-close="true">
      <ItemPhone :type="5" :compId="comp.id" @hide="popVisible=false"></ItemPhone>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getCompanyInfo } from "@/service/sale";
import { getPhoneNum } from "@/service/company";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import Label from "@/components/Label.vue";
import ItemPhone from '@/components/comp/ItemPhone.vue';
import ImgView from '@/components/comp/ImgView.vue';
export default {
  components: {
    Label,ItemPhone,ImgView
  },
  props: ["comp"],
  data() {
    return {
      imgUrl: IMG_URL_PRE,  // 图片地址
      staticUrl: STATIC_URL_PRE, // 静态图片地址
      popTitle: null,
      popVisible: false,
      empOptions: [
        { value: 1, label: '1-20人' },
        { value: 2, label: '21-50人' },
        { value: 3, label: '51-100人' },
        { value: 4, label: '101-200人' },
        { value: 5, label: '201-500人' },
        { value: 6, label: '501-1000人' },
        { value: 7, label: '1001人以上' }
      ],
      compInfo: null, // 资质信息
      phoneNum: '', // 查看电话号码的数量
    };
  },
  async created() {
    this.compInfo = this.comp;
    if (this.compInfo.aptitude&&this.compInfo.aptitude.stat&&this.compInfo.aptitude.stat.n_employee) {
      let obj=this.empOptions.find((e)=> {
          return e.value == this.compInfo.aptitude.stat.n_employee;
      })
      this.compInfo.aptitude.stat.n_employee = obj.label;
    }
    console.log(this.compInfo);
    this.phoneNum = await getPhoneNum({comp_id:this.compInfo.id,channel:5});
  },
  mounted() {},
  methods:{
    get_phone() {
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
  }
};
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.block {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  margin: 24px 0;
  min-width: 1200px;
}
.container {
  width: 80%;
  min-width: 1000px;
  margin: 0 auto;
  h3 {
    color: #4d4d4d;
    border-bottom: 1px solid #ccc;
    padding: 16px 100px;
    font-weight: bold;
    margin-bottom: 0;
  }
}
.block-1 {
  .container {
    height: 100%;
    padding-top: 28px;
  }
  .block-1-top {
    // height: 184px;
    background: linear-gradient(
      180deg,
      rgba(70, 184, 165, 0.6) 0%,
      rgba(154, 235, 193, 0.6) 100%
    );
    .comp-info {
      padding-left: 100px;
      .info-top {
        display: flex;
        margin-bottom: 10px;
        img {
          width: 100px;
          height: 100px;
          margin-right: 40px;
        }
        .img-right {
          > div {
            color: #4d4d4d;
            margin-top: 10px;
            &:first-child {
              margin-top: 0;
            }
          }
          .name {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .block-1-bottom {
    padding: 24px 0;
    font-size: 14px;
    color: #808080;
    .b1b-title {
      font-size: 16px;
      font-weight: bold;
      color: #4d4d4d;
      margin-right: 14px;
    }
  }
}
.block-2 {
  .block-2-list {
    padding: 16px 75px 32px 75px;
    .block-2-item {
      display: inline-block;
      vertical-align: top;
      width: 310px;
      padding: 14px;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin:0 25px 25px 25px;
      .b2i-title {
        font-size: 20px;
        color: #4d4d4d;
        font-weight: bold;
        line-height: 28px;
        margin-top: 20px;
        margin-bottom: 16px;
      }
      .b2i-desc {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
      }
    }
  }
}
.block-3 {
  .block-3-list {
    padding: 40px 100px;
    position: relative;
    .b3i-line {
        width: 5px;
        height: calc(100% - 100px);
        background: #808080;
        position: absolute;
        top: 40px;
        left: 140px;
        z-index: 0;
    }
    .block-3-item {
      width: 700px;
      background: transparent;
      border-radius: 4px;
      margin-bottom: 8px;
      position: relative;
      .b3i-square {
        width:40px;
        height:40px;
        background:rgba(245,245,245,1);
      }
      .b3i-con {
          width: calc(100% - 200px);
      }
      .b3i-time {
        width:128px;
        height:40px;
        line-height: 40px;
        text-align: center;
        background:rgba(230,162,60,1);
        border-radius:0px 20px 20px 0px;
        font-size: 16px;
        color: #fff;
      }
      .b3i-text {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        padding: 10px;
      }
    }
  }
}
.block-4 {
  .block-4-list {
    padding: 24px 100px;
    .block-4-item {
      background: #fff;
      box-sizing: border-box;
      border-bottom: 1px dashed #CCCCCC;
      padding-bottom: 24px;
      margin-bottom:24px;
      .b4i-title {
        font-size: 20px;
        color: #4d4d4d;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 16px;
      }
      .b4i-desc {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
      }
    }
    .block-4-item:last-child {
        margin-bottom:0;
    }
  }
}
.block-5 {
  .container {
    .partner {
      padding: 40px 100px;
      img {
        height: 116px;
        margin-right: 54px;
      }
    }
  }
}
</style>