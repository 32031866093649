<template>
  <div v-if="compInfo">
    <div class="block block-1">
      <div class="block-1-top">
        <div class="container">
          <div class="comp-info">
            <div class="info-top">
              <img v-if="compInfo && compInfo.logo" :src="imgUrl + compInfo.logo" />
              <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
              <div class="img-right">
                <div v-if="compInfo" class="name">{{compInfo.name}}</div>
                <!-- <div v-if="compInfo && compInfo.aptitude" style="margin-right:32px">
                  <span>公司规模：</span>
                  {{compInfo.aptitude.stat.n_employee}}
                </div>
                <div v-if="compInfo && compInfo.aptitude && compInfo.aptitude.stat">
                  <span>注册时间：</span>
                  {{compInfo.aptitude.stat.reg_date | moment('YYYY年MM月')}}
                </div> -->
                <div>
                  <a @click="get_phone()">
                    <i class="iconfont icon-dianhuabeifen"></i> 获取联系方式（{{phoneNum && phoneNum.total}}）</a>
                </div>
                <div class="flex">
                  <div v-if="compInfo && compInfo.aptitude" style="margin-right:32px">
                    <span>公司规模：</span>
                    {{compInfo.aptitude.stat.n_employee}}
                  </div>
                  <div v-if="compInfo && compInfo.aptitude && compInfo.aptitude.stat">
                    <span>注册时间：</span>
                    {{compInfo.aptitude.stat.reg_date | moment('YYYY年MM月')}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="compInfo && compInfo.aptitude && compInfo.aptitude.ability_service" class="label-wrap">
              <Label
                v-for="(item, i) in compInfo.aptitude.ability_service.zdcp"
                :key="i"
                :name="item.v"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="block block-2">
      <div class="container">
        <h3>经营店铺：</h3>
        <div
          v-if="compInfo.aptitude && compInfo.aptitude.store && compInfo.aptitude.store.length>0"
          class="block-2-list"
        >
          <div v-for="(item,idx) in compInfo.aptitude.store" :key="idx" class="block-2-item">
            <div>
              <label>店铺名称：</label>
              <span>{{item.name}}</span>
            </div>
            <div>
              <label>渠道：</label>
              <span>{{item.channel}}</span>
            </div>
            <div>
              <label>项目链接：</label>
              <a :href="item.url" target="_bank">{{item.url}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-2">
      <div class="container">
        <h3>品牌证书：</h3>
        <div
          v-if="compInfo.aptitude && compInfo.aptitude.brand && compInfo.aptitude.brand.length>0"
          class="block-2-list"
        >
          <div v-for="(item,idx) in compInfo.aptitude.brand" :key="idx" class="block-2-item">
            <div style="margin-bottom:30px">
              <label>品牌名称：</label>
              <span>{{item.name}}</span>
            </div>
            <div class="flex">
              <label>品牌证书：</label>
              <div class="flex-1">
                <img v-for="(item,idx) in item.photo" :key="idx" :src="imgUrl+item">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="popVisible" 
      width="500px" :destroy-on-close="true">
      <ItemPhone :type="5" :compId="comp.id" @hide="popVisible=false"></ItemPhone>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getCompanyInfo } from "@/service/sale";
import { getPhoneNum } from "@/service/company";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import Label from "@/components/Label.vue";
import ItemPhone from '@/components/comp/ItemPhone.vue';
export default {
  components: {
    Label,ItemPhone
  },
  props: ["comp"],
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      popTitle: null,
      popVisible: false,
      empOptions: [
        { value: 1, label: '1-20人' },
        { value: 2, label: '21-50人' },
        { value: 3, label: '51-100人' },
        { value: 4, label: '101-200人' },
        { value: 5, label: '201-500人' },
        { value: 6, label: '501-1000人' },
        { value: 7, label: '1001人以上' }
      ],
      compInfo: null,
      phoneNum: '', // 查看电话号码的数量
    };
  },
  async created() {
    this.compInfo = this.comp;
    if (this.compInfo.aptitude&&this.compInfo.aptitude.stat&&this.compInfo.aptitude.stat.n_employee) {
      let obj=this.empOptions.find((e)=> {
          return e.value == this.compInfo.aptitude.stat.n_employee;
      })
      this.compInfo.aptitude.stat.n_employee = obj.label;
    }
    console.log(this.compInfo);
    this.phoneNum = await getPhoneNum({comp_id:this.compInfo.id,channel:5});
  },
  mounted() {},
  methods:{
    get_phone() {
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
  }
};
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.block {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  margin: 24px 0;
  min-width: 1200px;
}
.container {
  width: 80%;
  min-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  h3 {
    color: #4d4d4d;
    border-bottom: 1px solid #ccc;
    padding: 16px 100px;
    font-weight: bold;
    margin-bottom: 0;
  }
}
.block-1 {
  .container {
    height: 100%;
    padding-top: 28px;
  }
  .block-1-top {
    // height: 184px;
    background: linear-gradient(
      180deg,
      rgba(70, 184, 165, 0.6) 0%,
      rgba(154, 235, 193, 0.6) 100%
    );
    .comp-info {
      padding-left: 100px;
      .info-top {
        display: flex;
        margin-bottom: 10px;
        img {
          width: 100px;
          height: 100px;
          margin-right: 40px;
        }
        .img-right {
          > div {
            color: #4d4d4d;
            margin-top: 10px;
            &:first-child {
              margin-top: 0;
            }
          }
          .name {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .block-1-bottom {
    padding: 24px 0;
    font-size: 14px;
    color: #808080;
    .b1b-title {
      font-size: 16px;
      font-weight: bold;
      color: #4d4d4d;
      margin-right: 14px;
    }
  }
}
.block-2 {
  .block-2-list {
    margin: 20px 100px 20px;
    background:#fff;
    .block-2-item {
      padding: 16px 10px;
      background: rgba(0, 0, 0, 0.02);
      margin-bottom: 25px;
      >div {
        font-size: 14px;
        color: #808080;
        line-height: 22px;
        margin-bottom: 10px;
        label {
          display: inline-block;
          width: 90px;
          text-align: right;
          font-size: 16px;
          margin-right: 5px;
        }
      }
      >div:last-child {
        margin-bottom: 0;
      }
      img {
        width: 180px;
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
  }
}

</style>